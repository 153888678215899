$(document).ready(function(){
    $("#contact_form").submit(function(e){
        e.preventDefault();

        grecaptcha.enterprise.execute('6Lc_zJgqAAAAADznhHod5rXxovN7cC5ERajLhY-N', { action: 'submit' })
            .then(function(token) {
                document.getElementById('g-recaptcha-response').value = token; // Store token
                $.post('/form-submit', {
                    'name': $('input[name=name]').val(),
                    'email': $('input[name=email]').val(),
                    'message': $('[name=message]').val(),
                    'g-recaptcha-response': $('#g-recaptcha-response').val()
                }, function(response){
                    //load json data from server and output message
                    var output = '';
                    if (response.type === 'error') {
                        output = '<div class="error">' + response.message + '</div>';
                    } else {
                        output = '<div class="success">' + response.message + '</div>';

                        //reset values in all input fields
                        $('#contact_form input').val('');
                        $('#contact_form textarea').val('');
                    }

                    $("#result").hide().html(output).slideDown();
                }, 'json');
            })
            .catch(function(error) {
                console.error("reCAPTCHA Error:", error);
                const result = document.getElementById('result');
                result.textContent = "reCAPTCHA verification failed. Please try again.";
                result.style.color = "red";
            });

        return false;
    });
    
    //reset previously set border colors and hide all message on .keyup()
    $("#contact_form input, #contact_form textarea").keyup(function(){
        $("#contact_form input, #contact_form textarea").css('border-color', '');
        $("#result").slideUp();
    });
    
});
